import React from "react";
import { Link } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"
import IncomeRating from "./IncomeRating";

export default function IncomeCard(props) {
  return (
      <div className="incomeCard">
        {props.image && <GatsbyImage image={props.image?.childImageSharp.gatsbyImageData} alt={props.imgAlt ? props.imgAlt : ""} className="incomImage" />}
        <div className="incomeCardContent">
          <h4 className="font-bold text-3xl  text-gray-900 leading-tight mb-4">{props.title}</h4>
          <IncomeRating title={"Tidskrävande"} rating={props.time}/>
          <IncomeRating title={"Potential"} rating={props.potential}/>
          <IncomeRating title={"Kunskaper"} rating={props.knowledge}/>
          <Link to={props.link} aria-label="">
              <button className="btnIncome">LÄS MER  ❯❯</button>
            </Link>
        </div>
      </div>
  );
}
