import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import IncomeCard from "../components/Inkomster/IncomeCard";

export default function Inkomster({ data }) {
  return (
    <>
      <Page
        title="En komplett guide för att tjäna extra pengar (2022)"
        description="En komplett guide för att börja tjäna extra pengar redan idag. Alltifrån på sociala medier, programmering till saker såsom börsen och fastigheter."
      >
          <div className="container m-auto mt-8 p-4 max-w-6xl">
              <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Inkomster</h1>
              <p className="mt-6 text-gray-600">Det finns massor av sätt att tjäna pengar på nätet idag. Man kan göra det via sociala medier, youtube, blogga, eller utveckla appar, hemsidor och mycket mer. Det finns en mycket <b>stor potential</b>, och det finns många som livnär sig helt och hållet från inkomster på nätet i dagen samhälle.</p>
              <p className="mt-4 text-gray-600">Nedan har jag samlat <b>olika inkomstmetoder</b> som du använda dig av för att börja tjäna pengar idag. Min tro är att det skall göra dig enklare för dig i denna djungel och potentiellt hitta nya inkomstidéer. Listan kommer fyllas på med nya metoder och tekniker eftersom.</p>
              <br />
              <div className="incomeContainer">
                <IncomeCard image={data.image} title={"Programmering"} link={"/inkomster/programmering/"} time={49} potential={96} knowledge={86} />
                <IncomeCard image={data.image} title={"Instagram"} link={"/inkomster/instagram/"} time={75} potential={76} knowledge={29} />
                <IncomeCard image={data.image} title={"Börsen"} link={"/sparande/"} time={36} potential={86} knowledge={78} />
                <IncomeCard image={data.image} title={"Bloggar"} link={"/inkomster/blogg/"} time={74} potential={68} knowledge={24} />
                <IncomeCard image={data.image} title={"Youtube"} link={"/inkomster/youtube/"} time={84} potential={72} knowledge={57} />
                <IncomeCard image={data.image} title={"Fotografering"} link={"/inkomster/fotografering/"} time={74} potential={56} knowledge={70} />
                <IncomeCard image={data.image} title={"Fastigheter"} link={"/inkomster/fastigheter/"} time={52} potential={86} knowledge={67} />
                <IncomeCard image={data.image} title={"Tiktok"} link={"/inkomster/tiktok/"} time={72} potential={86} knowledge={29} />
                <IncomeCard image={data.image} title={"Betalda undersökningar"} link={"/inkomster/undersokningar/"} time={54} potential={13} knowledge={7} />
              </div>
            <br />
            <br />
        </div>
      </Page>
    </>
  );
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "savingImage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    },
  }
`;
