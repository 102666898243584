import React from "react";

export default function IncomeRating(props) {
    return (
        <div className="income-rating">
            <div>{props.title}</div>
            <div className="bar">
                <div className="inside" style={{ width: (props.rating) + "%" }}></div>
            </div>
        </div>

    );
}
